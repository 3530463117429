import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Image from "next/image";
import pvLogo from "public/images/pv-logo-color.svg";

function LogoElement({
  variant,
  onClick,
}: {
  variant: "blue" | "white";
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}) {
  return (
    <>
      <Box
        onClick={onClick}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <Image
          alt="logo"
          src={pvLogo}
        />
      </Box>
    </>
  );
}

export default LogoElement;
