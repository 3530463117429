import "../styles/globals.css";
import "../public/fonts/fonts.css";
import type { AppProps } from "next/app";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "@styles/Theme";
import { MDXProvider } from "@mdx-js/react";
import mdxComponents from "@styles/mdxComponents";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import Navbar from "@client/components/navigation/Navbar";
import Footer from "@client/components/Footer";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath } = useRouter();
  const isCookiePolicyPage = asPath === "/cookiePolicy";
  return (
    <>
      <React.Fragment>
        <Head>
          <title>pensioenoptimalisatie</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
        </Head>
        <MDXProvider components={mdxComponents}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar />
            <Component {...pageProps} />
            {!isCookiePolicyPage ? <Footer /> : null}
          </ThemeProvider>
        </MDXProvider>
      </React.Fragment>
    </>
  );
}

export default MyApp;
