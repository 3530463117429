import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";

export const sizes = {
  navBarHeight: "120px",
};

export const colors = {
  background: {
    default: "#f4f4f4", // this is light grey from PV
    paper: "#fff",
  },
  primary: {
    main: "#870930", // this is burgundy from PV.
    dark: "#ff6600",
    light: "#0D03CA", // light blue by partena
    contrastText: "#fff",
  },
  secondary: {
    main: "#ff6600", // this is orange by PV.
    dark: "#66afe9", //
    light: "#addfe4", // this is blue by pv
    contrastText: "#fff",
  },
  error: {
    main: "#870930", // PV's red for errors
  },
  info: {
    main: "#53ac6c",
  },
  success: {
    main: "#3AD06A", // partena's Green for sucess.
    dark: "209E3C",
    light: "#9EF09C",
  },
  grey: {
    main: "#4d4d4d", // PV's dark grey
    "500": "#666666", // normal grey
    light: "#9D9D9C", // partena's light grey
  },
  component: {
    paperBorderColor: "#E7EEF8",
    inputBorderColor: "#B4B4B4",
    inputBorderColorHover: "#addfe4",
  },
};

export let theme: Theme = createTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: ["Open Sans", "Verdana", "sans-serif", "-apple-system"].join(
      ","
    ),
    fontSize: 10,
    h1: { fontSize: "2.62rem", lineHeight: "1.4", fontFamily: "Open Sans" },
    h2: {
      fontSize: "2.25rem",
      lineHeight: "1.4",
      fontFamily: "Open Sans",
    },
    h3: {
      fontSize: "1.875rem",
      lineHeight: "1.4",
      fontFamily: "Open Sans",
    },
    h4: {
      fontSize: "1.5rem",
      lineHeight: "1.4",
      fontFamily: "Open Sans",
    },
    h5: {
      fontSize: "1.25rem",
      lineHeight: "1.4",
      fontFamily: "Open Sans",
    },
    h6: {
      fontSize: "1rem",
      lineHeight: "1.4",
      fontFamily: "Open Sans",
    },
    // used for titles in the forms
    subtitle1: {
      fontSize: "1rem",
      fontFamily: "Open Sans",
      fontWeight: "700",
      // letterSpacing: "0.3px",
    },
    // define a subtitle2
    subtitle2: {
      fontSize: "0.9rem",
      fontFamily: "Open Sans",
    },
    //regular body text
    body1: {
      fontSize: "1rem",
      fontFamily: "Open Sans",
      fontWeight: 500,
      color: colors.grey[500],
    },
    body2: {
      fontSize: "0.8rem",
      fontFamily: "Open Sans",
      fontWeight: 500,
      color: colors.grey[500],
    },
  },

  //---------- Global colors ----------
  palette: {
    background: colors.background,
    primary: colors.primary,
    secondary: colors.secondary,
    error: colors.error,
    info: colors.info,
    success: colors.success,
    grey: colors.grey,
  },

  shape: {
    borderRadius: 0,
  },

  //---------- Global components styling ----------
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: "100vh",
          "&::nth-of-type(1)": {
            minHeight: "100vh",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: "p",
          subtitle2: "p",
        },
      },
      styleOverrides: {
        root: {
          variantMapping: {
            subtitle1: "p",
            subtitle2: "p",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 5,
          marginLeft: 0,
          fontSize: 16,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          paddingLeft: 10,
        },
      },
    },
    //---------- Navbar styling ----------
    MuiAppBar: {
      styleOverrides: {
        root: {
          border: "none",
          borderBottom: `1px solid ${colors.component.paperBorderColor}`,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          borderRight: "none",
        },
        paper: {
          border: "none",
          borderRight: `1px solid ${colors.component.paperBorderColor}`,
        },
      },
    },
    //---------- Global button styling ----------
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
          textTransform: "none",
          backgroundColor: "#FFFFFF",
          // border: "1px solid ",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "medium",
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          padding: "12px 24px",
          height: 48,
          borderRadius: "4px",
          "&:hover": {
            boxShadow: "none",
          },
        },
        outlined: {
          border: `1px solid`,
          ":hover": {
            border: `1px solid`,
            background: "#F4F4F4",
          },
        },
        contained: {
          border: "1px solid #870930",
          color: "#870930",
          background: "#addfe4",
          ":hover": {
            border: `1px solid`,
            background: "#c7e9ec",
          },
        },
      },
    },

    //---------- General UI div elements - paper, card, etc ----------
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: `1px solid ${colors.component.paperBorderColor}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.component.paperBorderColor}`,
          boxShadow: "none",
        },
      },
    },

    // ---------- Global form input styling ----------

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "4px 5px",
          backgroundColor: "#FFFFFF",
        },
        root: {
          color: colors.grey[500],
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${colors.component.inputBorderColor}`,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `2px solid ${colors.secondary.dark}`,
            },
          },
          "&:hover:not(.Mui-focused)": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${colors.component.inputBorderColorHover}`,
            },
          },
          "&.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${colors.error.main} !important`,
            },
          },
        },
      },
    },
    //MuiButtonBase-root-MuiToggleButton-root.Mui-selected:
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiToggleButtonGroup-grouped": {
            boxShadow: "none",
            textTransform: "none",
            borderRadius: "4px",
            "&:hover": {
              boxShadow: "none",
            },
            border: `1px solid ${colors.component.inputBorderColor}`,
          },

          "&.Mui-selected": {
            border: `1px solid ${colors.primary.main} !important`,
            backgroundColor: `${colors.secondary} !important`,
            color: "#870930 !important",
            zIndex: 1,
          },
          "&.MuiToggleButtonGroup-grouped:hover": {
            border: `1px solid ${colors.primary.light} !important`,

            zIndex: 1,
          },
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            border: `1px solid ${colors.component.inputBorderColor}`,
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);
