import OfflineBoltRoundedIcon from "@mui/icons-material/OfflineBoltRounded";
import {
  Box,
  Button,
  Link as MuiLink,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import footerJson from "@public/cms/footer.json";
import { FooterText } from "@public/cms/types";
import selectText from "helpers/selectText";
import Link from "next/link";
import { useRouter } from "next/router";

export default function Footer() {
  const router = useRouter();
  const language = router.locale;
  const footerText = selectText(
    footerJson,
    language as "nl" | "fr"
  ) as FooterText;

  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
  }));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 1, sm: 1 },
          justifyContent: {
            xs: "center",
            sm: "space-around",
            md: "space-between",
          },
          alignItems: { xs: "center" },
          paddingLeft: { md: "64px" },
          paddingRight: { md: "64px" },
          mb: 1.5,
        }}
      >
        <Stack direction={"row"} spacing={2} alignItems="center">
          <Typography>© 2024 P&V Verzekeringen</Typography>
          <Box sx={{ display: "flex" }}>
            <OfflineBoltRoundedIcon />
            <Typography>
              <MuiLink
                target="_blank"
                rel="noopener"
                href="https://www.aeco.cloud"
                color="inherit"
                underline="hover"
              >
                Powered by Aeco
              </MuiLink>
            </Typography>
          </Box>
        </Stack>
        <Stack direction={"row"} spacing={2} alignItems="center">
          <Typography>
            <MuiLink
              target="_blank"
              rel="noopener"
              href={
                language === "fr"
                  ? "https://www.pv.be/fr/notre-politique-de-cookies"
                  : "https://www.pv.be/nl/ons-cookiebeleid"
              }
              color="inherit"
              underline="hover"
            >
              {footerText.cookiePolicy}
            </MuiLink>
          </Typography>
          <Typography>
            <Link
              target="_blank"
              rel="noopener"
              passHref
              href={language === "fr" ? "/fr/disclaimer" : "/disclaimer"}
            >
              <MuiLink color="inherit" underline="hover">
                {footerText.disclaimerFooter}
              </MuiLink>
            </Link>
          </Typography>
          <Typography>
            <MuiLink
              target="_blank"
              rel="noopener"
              href={
                language === "fr"
                  ? "https://www.pv.be/fr/privacy"
                  : "https://www.pv.be/nl/privacy"
              }
              color="inherit"
              underline="hover"
            >
              {footerText.privacy}
            </MuiLink>
          </Typography>
        </Stack>
      </Box>
    </>
  );
}
