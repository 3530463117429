import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { sizes, theme } from "@styles/Theme";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import LogoElement from "../LogoElement";
import { useRouter } from "next/router";
import main from "@public/cms/main.json";
import selectText from "helpers/selectText";
import { MainText } from "@public/cms/types";

export default function Navbar() {
  const router = useRouter();
  const isCookiePolicyPage = router.asPath === "/cookiePolicy";
  const language = router.locale;
  const mainText = selectText(main, language as "nl" | "fr") as MainText;

  {
    const LanguageButtonsComponent = (
      <Box sx={{ color: theme.palette.primary.main, ml: 2 }}>
        <Stack
          direction={"row"}
          gap={0}
          sx={{ height: "100%" }}
          alignItems={"center"}
        >
          <Button
            sx={{
              height: "inherit",
              padding: 1,
              width: "max-content",
              minWidth: 0,
            }}
            onClick={() => changeLocale("nl")}
          >
            NL
          </Button>
          <Divider
            textAlign="center"
            sx={{
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
              m: 0,
              height: "60%",
            }}
            variant="middle"
          />
          <Button
            sx={{
              height: "inherit",
              padding: 1,
              width: "max-content",
              minWidth: 0,
            }}
            onClick={() => changeLocale("fr")}
          >
            FR
          </Button>
        </Stack>
      </Box>
    );

    const changeLocale = async (locale: "nl" | "fr") => {
      router
        .push(router.asPath, undefined, {
          locale,
        })
        .then(() => {
          if (isCookiePolicyPage) router.reload();
        });
    };

    const redirectHomepage = async () => {
      if ("/" == router.asPath) {
        router.reload();
      } else {
        await router.push("/");
      }
    };

    return (
      <>
        <AppBar
          elevation={0}
          sx={{
            height: sizes.navBarHeight,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Toolbar
            sx={{
              height: "100%",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <LogoElement onClick={redirectHomepage} variant="white" />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <a
                  target={"_blank"}
                  href={
                    language === "fr"
                      ? "https://www.pv.be/fr/home"
                      : "https://www.pv.be/nl/home"
                  }
                >
                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      display: { xs: "none", sm: "inherit" },
                      textColor: "#000000",
                    }}
                  >
                    <Typography
                      fontWeight={700}
                      color={theme.palette.primary.main}
                    >
                      {mainText.toPVVerzekeringen}
                    </Typography>
                  </Button>
                </a>
                {LanguageButtonsComponent}
                {/* custom dropdown input for localization => has it's own look compared to the form inputs */}
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}
